export const photos = [
    {
      src: require("../../assets/images/web-design/jsla.png").default,
      id: 0
    },
    {
      src: require("../../assets/images/web-design/breadtalk.png").default,
      id: 1
    },
    {
      src: require("../../assets/images/web-design/cibi.png").default,
      id: 2
    },
    {
      src: require("../../assets/images/web-design/filmex.png").default,
      id: 3
    },
    {
      src: require("../../assets/images/web-design/artifice.png").default,
      id: 4
    },
    {
      src: require("../../assets/images/web-design/kenny.png").default,
      id: 5
    },
    {
      src: require("../../assets/images/web-design/netcare.png").default,
      id: 6
    },
    {
      src: require("../../assets/images/web-design/pazifik.png").default,
      id: 7
    },
    {
      src: require("../../assets/images/web-design/pga.png").default,
      id: 8
    },
    {
      src: require("../../assets/images/web-design/philcom.png").default,
      id: 9
    },
    {
      src: require("../../assets/images/web-design/sea-to-sky.png").default,
      id: 10
    },
    {
      src: require("../../assets/images/web-design/suanti.png").default,
      id: 11
    },
    {
      src: require("../../assets/images/web-design/vc-leadership.png").default,
      id: 12
    },
    {
      src: require("../../assets/images/web-design/whiteport.png").default,
      id: 13
    },
    
  ];
  